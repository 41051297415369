import React, { useState } from 'react';
import { withRouter } from "react-router";
import CampaignForm from '../../libraries/campaign-form';
import Header from '../../../../libraries/header';
import Footer from '../../../../libraries/footer';
import config from '../../../../../config';
import postCampaign from './helpers/postCampaign';
import './index.scss';

let isFetching = false;
export const CampaignEdit = withRouter(({ history, match }) => {
  const id = match.params.id;
  const [campaign, setCampaign] = useState();

  if (!campaign || campaign.id !== (1*id)) {
    if (!isFetching) {
      isFetching = true;
      fetch(`${config.host}/campaign/${id}/retrieve`, {credentials: 'include'})
        .then(res => res.json())
        .then(json => {
          isFetching = false;
          if (json.status === 403) history.push('/login');
          if ('msg' in json) setCampaign({
            id: json.msg.id,
            title: json.msg.title,
            description: json.msg.description,
            beg_timestamp: new Date(json.msg.begin_timestamp),
            end_timestamp: new Date(json.msg.end_timestamp),
            can_update: json.msg.can_update,
            campaign_type_id: json.msg.campaign_type_id,
            data: json.msg.hosts || json.msg.impressions || json.msg.views,
          });
        });
    }
  }

  const submitForm = async (data) => {
    let url = null;
    switch (data.campaignType) {
      case 1:
        url = `/campaign/exclusive/${id}/update`;
        break;
      case 2:
        url = `/campaign/cpm/${id}/update`;
        break;
      case 3:
        url = `/campaign/cpmv/${id}/update`;
        break;
      default:
        break;
    }
    return postCampaign({url, params: data});
  };

  let content = 'Loading..';
  if (campaign) {
    content = (
      <CampaignForm
        campaign={campaign}
        history={history}
        submitForm={submitForm}
      />
    )
  }

  return (
    <div className="campaign-edit">
      <Header />
      <div className="container">
        <div className="campaign-edit__content">
          <div className="campaign-edit__header">
            <h2 className="campaign-edit__title">Edit Campaign</h2>
          </div>
          {content}
        </div>
      </div>
    <Footer />
    </div>
  );
});

export default CampaignEdit;
