import React from 'react';
import './index.scss';

export const Footer =() => (
  <div className="footer">
    Copyright 2019 Adroady.
  </div>
);

export default Footer;
