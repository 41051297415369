import fetch from 'node-fetch';
import config from '../../../../config';

export const postLogin = ({
  email,
  password,
}) => {
  return fetch(`${config.host}/advertiser/login`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({
        email,
        password,
      })
    })
    .then(res => res.json())
    .then(json => {
      return json;
    });
};

export default postLogin;
