import fetch from 'node-fetch';
import config from '../../../../../../config';

export const postCampaign = ({
  url,
  params
}) => {
  return fetch(`${config.host}${url}`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify(params)
    })
    .then(res => res.json())
    .then(json => {
      return json;
    });
};

export default postCampaign;

