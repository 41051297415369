import React from 'react';
import Header from '../../libraries/header';

export const UserManagement = () => (
  <div>
    <Header />
    <div className="container">
      User Management
    </div>
  </div>
);
