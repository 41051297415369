export const getCampaignType = (id) => {
  switch (id) {
    case 1:
      return 'Exclusive';
    case 2:
      return 'CPM';
    case 3:
      return 'CPMV';
    default:
      break;
  };
  return null;
};

export default getCampaignType;
