import React, { useState } from 'react';
import { withRouter } from "react-router";
import ScaleLoader from 'react-spinners/ScaleLoader';
import Header from '../../libraries/header';
import Footer from '../../libraries/footer';
import config from '../../../config';
import './index.scss';

const getSubmitbutton = (isLoading) => {
  if (isLoading) {
    return (
      <button className="account__submit" type="submit">
        <ScaleLoader
          sizeUnit={"px"}
          height={10}
          width={4}
          color="#fff"
        />
      </button>
    )
  }
  return (
    <button className="account__submit" type="submit">
      Update
    </button>
  )
};

const getFormMessage = ({ type, message }) => {
  if (!type) return null;
  if (type === 'error') {
    return (
      <div className="account__report account__report_error">
        <span><strong>Error.</strong> {message}</span>
      </div>
    )
  }
  if (type === 'success') {
    return (
      <div className="account__report account__report_success">
        <span><strong>Success.</strong> {message}</span>
      </div>
    )
  }
};

let isFetching = false;
let data = {
  email_address: '',
  legal_name: '',
  mobile_number: '',
  phone_number: '',
  tax_id: '',
  advertiser_photo: null,
  tax_id_photo: null,
};

export const Account = withRouter(({ history }) => {
  const [isLoadingSubmit, setLoadingSubmit] = useState(false);
  const [isLoadingAccount, setLoadingAccount] = useState(false);
  const [photo, setPhoto] = useState(data.advertiser_photo);
  const [taxPhoto, setTaxPhoto] = useState(data.tax_id_photo);
  const [legalName, setLegalName] = useState(data.legal_name);
  const [mobileNumber, setMobileNumber] = useState(data.mobile_number);
  const [phoneNumber, setPhoneNumber] = useState(data.phone_number);
  const [email, setEmail] = useState(data.email_address);
  const [taxId, setTaxId] = useState(data.tax_id);
  const [formReport, setFormReport] = useState({type: null, message: ''});

  if (!isFetching) {
    isFetching = true;
    setLoadingAccount(true);
    fetch(`${config.host}/advertiser/account`, {credentials: 'include'})
      .then(res => res.json())
      .then(json => {
        setLoadingAccount(false);
        if (json.status === 403) {
          history.push('/login');
        }
        if (json.success === true) {
          data = json.msg;
          setEmail(json.msg.email_address || '');
          setLegalName(json.msg.legal_name || '');
          setMobileNumber(json.msg.mobile_number || '');
          setPhoneNumber(json.msg.phone_number || '');
          setTaxId(json.msg.tax_id || '');
          setPhoto(json.msg.advertiser_photo);
          setTaxPhoto(json.msg.tax_id_photo);
        }
      });
  }

  const doSubmit = (e) => {
    e.preventDefault();
    if (isLoadingSubmit) return true;

    setLoadingSubmit(true);
    const formData = new FormData();
    const photo = document.getElementById('account_photo');
    const taxPhoto = document.getElementById('account_tax-id-photo');

    formData.append('legal_name', legalName);
    if (mobileNumber) formData.append('mobile_number', mobileNumber);
    if (phoneNumber) formData.append('phone_number', phoneNumber);
    if (taxId) formData.append('tax_id', taxId);
    if (photo.value) formData.append('advertiser_photo', photo.files[0]);
    if (taxPhoto.value) formData.append('tax_id_photo', taxPhoto.files[0]);

    fetch(`${config.host}/advertiser/account/update`, {
      method: 'put',
      body: formData,
      credentials: 'include',
    })
      .then(res => res.json())
      .then(json => {
        setLoadingSubmit(false);
        isFetching = false;
        if (json.success === false) {
          setFormReport({
            type: 'error',
            message: json.msg
          });
        } else {
          setFormReport({
            type: 'success',
            message: json.msg
          });
        }
      });
  };

  if (isLoadingAccount === true) {
    return (
      <div className="account">
        <Header />
        <div className="container">
          <div className="account__content">
            <div className="account__header">
              <h2 className="account__title">Account</h2>
            </div>
            <h3 className="account__section-header">Account Details</h3>
            <div className="account__item">
              <label className="account__label"></label>
              <div className="account__input">
                <ScaleLoader
                  sizeUnit={"px"}
                  height={10}
                  width={4}
                  color="#168BA2"
                />
              </div>
            </div>
            <div className="account__action"></div>
          </div>
        </div>
      </div>
    );
  }

  let photoPreview = null;
  let taxPhotoPreview = null;
  if (photo) {
    photoPreview = <img alt="" className="account__photo" src={`${config.host}${photo}`} />;
  }
  if (taxPhoto) {
    taxPhotoPreview = <img alt="" className="account__photo" src={`${config.host}${taxPhoto}`} />;
  }
  return (
    <div className="account">
      <Header />
      <div className="container">
        <div className="account__content">
          <form className="campaign-form" autoComplete="off" onSubmit={(e) => doSubmit(e)}>
            <div className="account__header">
              <h2 className="account__title">Account</h2>
            </div>
            {getFormMessage(formReport)}
            <h3 className="account__section-header">Account Details</h3>
            <div className="account__item">
              <label className="account__label" htmlFor="account_legal-name">Legal Name</label>
              <div className="account__input">
                <input
                  className="account__legal-name"
                  type="text"
                  id="account_legal-name"
                  value={legalName}
                  name="legal_name"
                  onChange={e => setLegalName(e.target.value)}
                />
              </div>
            </div>
            <div className="account__item">
              <label className="account__label" htmlFor="account_email">Email</label>
              <div className="account__input">
                <input
                  className="account__email"
                  type="text"
                  id="account_email"
                  disabled
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="account__item">
              <label className="account__label" htmlFor="account_photo">Photo</label>
              <div className="account__input-file">
                <div className="account__file-preview">{photoPreview}</div>
                <div className="account__file-update">
                  <p className="account__file-label">Upload new photo:</p>
                  <input
                    className="account__photo"
                    type="file"
                    id="account_photo"
                    name="advertiser_photo"
                  />
                </div>
              </div>
            </div>
            <div className="account__item">
              <label className="account__label" htmlFor="account_mobile-number">Mobile Number</label>
              <div className="account__input">
                <input
                  className="account__mobile-number"
                  type="text"
                  id="account_mobile-number"
                  name="mobile_number"
                  value={mobileNumber}
                  onChange={e => setMobileNumber(e.target.value)}
                />
              </div>
            </div>
            <div className="account__item">
              <label className="account__label" htmlFor="account_phone-number">Phone Number</label>
              <div className="account__input">
                <input
                  className="account__phone-number"
                  type="text"
                  id="account_phone-number"
                  name="phone_number"
                  value={phoneNumber}
                  onChange={e => setPhoneNumber(e.target.value)}
                />
              </div>
            </div>
            <div className="account__item">
              <label className="account__label" htmlFor="account_tax-id">Tax ID</label>
              <div className="account__input">
                <input
                  className="account__tax-id"
                  type="text"
                  id="account_tax-id"
                  name="tax_id"
                  value={taxId}
                  onChange={e => setTaxId(e.target.value)}
                />
              </div>
            </div>
            <div className="account__item">
              <label className="account__label" htmlFor="account_tax-id-photo">Tax ID's Photo</label>
              <div className="account__input-file">
                <div className="account__file-preview">{taxPhotoPreview}</div>
                <div className="account__file-update">
                  <p className="account__file-label">Upload new photo:</p>
                  <input
                    className="account__tax-id-photo"
                    type="file"
                    id="account_tax-id-photo"
                    name="tax_id_photo"
                  />
                </div>
              </div>
            </div>
            <div className="account__action">
              {getSubmitbutton(isLoadingSubmit)}
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  )
});

export default Account;
