import { useMedia } from '../../../../helpers/use-media';

export const getRootStyles = () => {
  const isPhone = useMedia("(min-width: 320px) and (max-width: 480px) and (min-height: 400px)");

  if (isPhone) {
    return {
      height: window.innerHeight
    };
  }

  return {
    marginTop: (window.innerHeight - 290) / 3
  };
};

export default getRootStyles;
