import React from 'react';
import { Route } from "react-router-dom";
import { Dashboard } from './components/dashboard';
import { Campaign } from './components/campaign';
import { Report } from './components/report';
import { UserManagement } from './components/user-management';
import { Account } from './components/account';

export const Secure = () => (
  <div>
    <Route path="/dashboard" exact component={Dashboard} />
    <Route path="/campaign" component={Campaign} />
    <Route path="/report" component={Report} />
    <Route path="/user-management" component={UserManagement} />
    <Route path="/account" component={Account} />
  </div>
);

export default Secure;
