import React from 'react';
import moment from 'moment';
import { withRouter } from "react-router";
import ScaleLoader from 'react-spinners/ScaleLoader';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import Header from '../../../../libraries/header';
import Footer from '../../../../libraries/footer';
import getCampaignType from './helpers/getCampaignType';
import ActionDelete from './components/action-delete';
import getCampaignList from '../../helpers/getCampaignList';
import './index.scss';

library.add(faEdit);

const getNodata = (data) => {
  if (data && data.length === 0) {
    return (
      <tr><td colSpan={5} className="campaign__nodata">No data available</td></tr>
    );
  }
  return null;
};

const getLoading = (data) => {
  if (data === null) {
    return (
      <tr>
        <td colSpan={5} className="campaign__loading">
          <ScaleLoader
            sizeUnit={"px"}
            height={10}
            width={4}
            color="#00C5DD"
          />
        </td>
      </tr>
    );
  }
  return null;
};

export const CampaignList = withRouter(({ history }) => {
  const rawData = getCampaignList(history);
  let nodata = getNodata(rawData);
  let loading = getLoading(rawData);
  const campaignList = rawData || [];

  return (
    <div className="campaign">
      <Header />
      <div className="container">
        <div className="campaign__content">
          <div className="campaign__header">
            <h2 className="campaign__title">Campaign</h2>
            <Link
              to="/campaign/new"
              className="campaign__new"
            >
              New Campaign
            </Link>
          </div>
          <table width="100%" cellPadding="0" cellSpacing="0" border="0">
            <thead>
              <tr>
                <th className="campaign__col-title">Title</th>
                <th className="campaign__col-type">Type</th>
                <th className="campaign__col-date">Start</th>
                <th className="campaign__col-date">End</th>
                <th className="campaign__col-actions">Actions</th>
              </tr>
            </thead>
            <tbody>
              {nodata}
              {loading}
              {campaignList.map((campaign) => (
                <tr key={campaign.id}>
                  <td className="campaign__col-title">
                    <div className="campaign__tdTitle">{campaign.title}</div>
                    <div className="campaign__tdDesc">{campaign.description}</div>
                  </td>
                  <td className="campaign__col-type">
                    <span className={`campaign__type campaign__type_${campaign.campaign_type_id}`}>
                      {getCampaignType(campaign.campaign_type_id)}
                    </span>
                  </td>
                  <td className="campaign__col-date">
                    {moment(new Date(campaign.beg_timestamp)).format('MMM DD, YYYY HH:mm')}
                  </td>
                  <td className="campaign__col-date">
                    {moment(new Date(campaign.end_timestamp)).format('MMM DD, YYYY HH:mm')}
                  </td>
                  <td className="campaign__col-actions">
                    <ActionDelete
                      history={history}
                      id={campaign.id}
                    />
                    <Link
                      className="campaign__action"
                      to={`/campaign/edit/${campaign.id}`}
                    >
                      <FontAwesomeIcon icon="edit" />
                    </Link>
                </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </div>
  )
});

export default CampaignList;
