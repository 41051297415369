import React from 'react';
import { Route, Redirect } from "react-router-dom";
import { CampaignList } from './components/campaign-list';
import { CampaignNew } from './components/campaign-new';
import { CampaignEdit } from './components/campaign-edit';

export const Campaign = ({ match }) => (
  <div>
    <Route exact path={match.path} render={() => <Redirect to={`${match.path}/list`} />}/>
    <Route path={`${match.path}/list`} component={CampaignList} />
    <Route path={`${match.path}/new`} component={CampaignNew} />
    <Route path={`${match.path}/edit/:id`} component={CampaignEdit} />
  </div>
);

export default Campaign;

