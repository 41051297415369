import React from 'react';
import { Link, NavLink } from "react-router-dom";
import { withRouter } from 'react-router';
import fetch from 'node-fetch';
import config from '../../../config';
import logo from './assets/logo.png';
import './index.scss';

export const Header = withRouter(({ history }) => (
  <div className="header">
    <div className="container">
      <Link className="header__logo-wrapper" to="/dashboard">
        <img className="header__logo" src={logo} width="110" height="30" alt="Adroady" />
      </Link>
      { /*<NavLink activeClassName='header__nav_selected' className="header__nav" to="/dashboard">Dashboard</NavLink> */ }
      <NavLink activeClassName='header__nav_selected' className="header__nav" to="/campaign">Campaign</NavLink>
      { /*<NavLink activeClassName='header__nav_selected' className="header__nav" to="/report">Report</NavLink> */ }
      { /*<NavLink activeClassName='header__nav_selected' className="header__nav" to="/user-management">User Management</NavLink> */ }
      <div className="header__right">
        <NavLink activeClassName='header__nav_selected' className="header__nav" to="/account">Account</NavLink>
        <a
          className="header__nav header__nav_logout"
          href="#logout"
          onClick={(e) => {
            e.preventDefault();
            fetch(`${config.host}/advertiser/logout`, {
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                credentials: 'include',
              })
              .then(res => res.json())
              .then(json => {
                history.push('/login');
              });
          }}
        >
          Logout
        </a>
      </div>
    </div>
  </div>
));

export default Header;

