import fetch from 'node-fetch';
import config from '../../../../config';

export const fetchSubmit = (email) => {
  return fetch(`${config.host}/advertiser/reset-password`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
      })
    })
    .then(res => res.json())
    .then(json => {
      return json;
    });
};

export default fetchSubmit;
