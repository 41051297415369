import React from 'react';

export const CampaignHostEmpty = () =>(
  <div>
    <h3 className="campaign-form__section-header">Campaign Host</h3>
    <div className="campaign-form__hosts">
      <div className="campaign-form__hosts-info">
        Select campaign type before you can configure the host.
      </div>
    </div>
  </div>
);

export default CampaignHostEmpty;
