import React from 'react';
import Header from '../../libraries/header';

export const Report = () => (
  <div>
    <Header />
    <div className="container">
      Report
    </div>
  </div>
);
