import React from 'react';
import CampaignHostExclusive from './components/campaign-host-exclusive';
import CampaignHostCPM from './components/campaign-host-cpm';
import CampaignHostCPMV from './components/campaign-host-cpmv';
import CampaignHostEmpty from './components/campaign-host-empty';

export const CampaignHost = ({
  campaignType = 0,
  value,
  onChange
}) => {
  switch(campaignType) {
    case 1:
      return <CampaignHostExclusive value={value} onChange={onChange} />
    case 2:
      return <CampaignHostCPM value={value} onChange={onChange} />
    case 3:
      return <CampaignHostCPMV value={value} onChange={onChange} />
    default:
      return <CampaignHostEmpty />
  }
};

export default CampaignHost;

