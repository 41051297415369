import fetch from 'node-fetch';
import config from '../../../../config';

export const fetchRegister = ({
  entity,
  email,
  password,
  verify
}) => {
  return fetch(`${config.host}/advertiser/register`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        entity,
        email,
        password,
        verify
      })
    })
    .then(res => res.json())
    .then(json => {
      return json;
    });
};

export default fetchRegister;
