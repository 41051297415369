import React, { useState} from 'react';
import { Link } from "react-router-dom";
import ScaleLoader from 'react-spinners/ScaleLoader';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import getCampaignTypes from './helpers/getCampaignTypes';
import { invalidateCampaignList } from '../../helpers/getCampaignList';
import CampaignHost from './components/campaign-host';
import './index.scss';
import "react-datepicker/dist/react-datepicker.css";

const validate = ({
  title,
  description,
  dateStart,
  dateEnd,
  campaignType,
  hosts
}) => {
  if (!title || !description || !dateStart || !dateEnd || !campaignType || !hosts) {
    return {
      type: 'error',
      message: 'All fields is required.'
    };
  }
};

const getFormMessage = ({ type, message }) => {
  if (!type) return null;
  if (type === 'error') {
    return (
      <div className="campaign-form__report campaign-form__report_error">
        <span><strong>Error.</strong> {message}</span>
      </div>
    )
  }
};

const getSubmitbutton = (isLoading) => {
  if (isLoading) {
    return (
      <button className="campaign-form__submit" type="submit">
        <ScaleLoader
          sizeUnit={"px"}
          height={10}
          width={4}
          color="#fff"
        />
      </button>
    )
  }
  return (
    <button className="campaign-form__submit" type="submit">
      Submit
    </button>
  )
};

export const CampaignForm = ({
  history,
  submitForm,
  campaign = {}
}) => {
  const campaignTypes = getCampaignTypes({ history }) || [];

  const [title, setTitle] = useState(campaign.title);
  const [description, setDescription] = useState(campaign.description);
  const [dateStart, setDateStart] = useState(campaign.beg_timestamp);
  const [dateEnd, setDateEnd] = useState(campaign.end_timestamp);
  const [campaignType, setCampaignType] = useState(campaign.campaign_type_id);
  const [hosts, setHosts] = useState(campaign.data);
  const [formReport, setFormReport] = useState({type: null, message: ''});
  const [isLoadingSubmit, setLoadingSubmit] = useState(false);

  let selectedKey = null;
  let isDisabled = false;
  let campaignSelectType = null;
  let datepickers = null;

  if (campaignTypes.length) {
    campaignTypes.map((item, key) => {
      if (item.value === campaign.campaign_type_id) {
        selectedKey = key;
        isDisabled = true;
      }
      return true;
    });
    campaignSelectType = (
      <Select
        isDisabled={isDisabled}
        value={campaignTypes[selectedKey]}
        options={campaignTypes}
        onChange={e => setCampaignType(e.value)}
      />
    );
    datepickers = (
      <div className="campaign-form__input">
        <DatePicker
          id="campaign_start"
          className="campaign-form__date-start"
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          dateFormat="MMM dd, yyyy - HH:mm"
          selected={dateStart}
          onChange={date => {
            if (!dateEnd || date < dateEnd) setDateStart(date);
          }}
          selectsStart
          startDate={dateStart}
          endDate={dateEnd}
        />
        <label className="campaign-form__date-separator" htmlFor="campaign_end">to</label>
        <DatePicker
          id="campaign_end"
          className="campaign-form__date-end"
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          dateFormat="MMM dd, yyyy - HH:mm"
          selected={dateEnd}
          onChange={date => {
            if (!dateStart || date > dateStart) setDateEnd(date);
          }}
          selectsEnd
          startDate={dateStart}
          endDate={dateEnd}
        />
      </div>
    );
  }

  const doSubmit = (e) => {
    e.preventDefault();
    const validationReport = validate({
      title,
      description,
      dateStart,
      dateEnd,
      campaignType,
      hosts
    });
    if (validationReport) {
      setFormReport(validationReport);
      return false;
    }
    setFormReport({type: null, message: ''});
    const postParams = {
      title,
      description,
      begin_timestamp: dateStart,
      end_timestamp: dateEnd,
      campaignType,
    };
    if (campaignType === 1) {
      postParams.hosts = hosts;
    } else if (campaignType === 2) {
      postParams.impressions = hosts;
    } else if (campaignType === 3) {
      postParams.views = hosts;
    }

    if (!isLoadingSubmit) {
      setLoadingSubmit(true);
      submitForm(postParams).then((response) => {
        setLoadingSubmit(false);
        if (response.success === false) {
          setFormReport({type: 'error', message: response.msg});
        } else {
          invalidateCampaignList();
          history.push('/campaign/list');
        }
      });
    }
  };

  return (
    <form className="campaign-form" autoComplete="off" onSubmit={(e) => doSubmit(e)}>
      {getFormMessage(formReport)}
      <h3 className="campaign-form__section-header">Campaign Details</h3>
      <div className="campaign-form__item">
        <label className="campaign-form__label" htmlFor="campaign_type">Type</label>
        <div className="campaign-form__input">
          {campaignSelectType}
        </div>
      </div>
      <div className="campaign-form__item">
        <label className="campaign-form__label" htmlFor="campaign_title">Title</label>
        <div className="campaign-form__input">
          <input
            className="campaign-form__title"
            type="text"
            id="campaign_title"
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
        </div>
      </div>
      <div className="campaign-form__item">
        <label className="campaign-form__label" htmlFor="campaign_description">Description</label>
        <div className="campaign-form__input">
          <textarea
            className="campaign-form__description"
            id="campaign_description"
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
        </div>
      </div>
      <div className="campaign-form__item">
        <label className="campaign-form__label" htmlFor="campaign_start">Period</label>
        {datepickers}
      </div>
      <CampaignHost
        value={hosts}
        campaignType={campaignType}
        onChange={(data) => setHosts(data)}
      />
      <div className="campaign-form__action">
        {getSubmitbutton(isLoadingSubmit)}
        <Link className="campaign-form__cancel" to="/campaign">Cancel</Link>
      </div>
    </form>
  );
};

export default CampaignForm;
