import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Select from 'react-select';
import ScaleLoader from 'react-spinners/ScaleLoader';
import fetchEntityTypes from './helpers/fetchEntityTypes';
import fetchRegister from './helpers/fetchRegister';
import getRootStyles from './helpers/getRootStyles';
import isValidEmail from './helpers/isValidEmail';
import logo from './assets/logo.png';
import './index.scss';

export const Register = () => {
  const entityTypes = fetchEntityTypes();
  const styles = getRootStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [verify, setVerify] = useState('');
  const [entity, setEntity] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);
  const [validationReport, setValidationReport] = useState({
    entity: true,
    email: true,
    password: true,
    verify: true,
    errorMessage: null,
  });

  let selectStyle = {
    borderRadius: 0,
    height: 48,
    borderWidth: 0,
    borderColor: 'red',
    borderStyle: 'solid'
  };
  let emailClassName = 'register__text';
  let passwordClassName = 'register__text';
  let verifyClassName = 'register__text';

  if (!validationReport.entity) {
    selectStyle.borderWidth = 1;
    selectStyle.backgroundColor = '#fee';
  }
  if (!validationReport.email) {
    emailClassName = 'register__text register__text_error';
  }
  if (!validationReport.password) {
    passwordClassName = 'register__text register__text_error';
  }
  if (!validationReport.verify) {
    verifyClassName = 'register__text register__text_error';
  }

  const doRegister = () => {
    if (isLoading) return true;

    const isValid = {
      entity: true,
      email: true,
      password: true,
      verify: true,
      errorMessage: null,
    };
    if (!entity.value) {
      isValid.entity = false;
    }
    if (!email) {
      isValid.email = false;
    }
    if (!password) {
      isValid.password = false;
    }
    if (!verify) {
      isValid.verify = false;
    }
    if (!isValid.entity || !isValid.email || !isValid.password || !isValid.verify) {
      isValid.errorMessage = 'All fields are required.';
    }

    if (!isValid.errorMessage) {
      if (!isValidEmail(email)) {
        isValid.email = false;
        isValid.errorMessage = 'Invalid email address.';
      } else if (password.length < 10) {
        isValid.password = false;
        isValid.errorMessage = 'Your password is too short. Your password should have at least 10 character.';
      } else if (password.length > 50) {
        isValid.password = false;
        isValid.errorMessage = 'Your password is too long. Your password cannot be more than 10 character.';
      } else if (password !== verify) {
        isValid.password = false;
        isValid.verify = false;
        isValid.errorMessage = 'Passsword and Confirm Password doesnt match.';
      }
    }

    if (isValid.errorMessage) {
      setValidationReport(isValid);
    } else {
      setIsLoading(true);
      fetchRegister({
        entity: entity.value,
        email,
        password,
        verify
      })
      .then((json) => {
        if (json.success === false) {
          setIsLoading(false);
          setValidationReport({
            entity: true,
            email: true,
            password: true,
            verify: true,
            errorMessage: 'Registration failed. Please try again later.'
          });
        } else {
          setValidationReport({
            entity: true,
            email: true,
            password: true,
            verify: true,
            errorMessage: ''
          });
          setIsLoading(false);
          setIsSuccess(true);
        }
      }).catch(function(error) {
        setIsLoading(false);
        setValidationReport({
          entity: true,
          email: true,
          password: true,
          verify: true,
          errorMessage: 'Registration failed. Please try again later.'
        });
      });
    }
  };

  let errorMessage = null;
  if (validationReport.errorMessage) {
    errorMessage = (
      <div className="register__error-message">
        {validationReport.errorMessage}
      </div>
    );
  }

  let registerText = 'Register';
  if (isLoading) {
    registerText = (
      <ScaleLoader
        sizeUnit={"px"}
        height={10}
        width={4}
        color="#005567"
      />
    );
  }

  let rootClassName = 'register';
  if (isSuccess) {
    rootClassName = 'register register_success';
  }

  return (
    <div className={rootClassName} style={styles}>
      <div className="register__container">
        <div className="register__header">
          <img src={logo} alt="Adroady" className="register__logo" height="30" width="110" />
          <h3 className="register__title">Register</h3>
        </div>
        <div className="register__content">
          {errorMessage}
          <div className="register__success-message">
            Success. Please check your email to complete registration process.
          </div>
          <div className="register__form-wrapper">
            <Select
              placeholder="Select Type"
              options={entityTypes}
              onChange={(selected) => setEntity(selected)}
              styles={{
                control: styles => ({
                  ...styles,
                  ...selectStyle
                })
              }}
            />
            <p className="register__entity-info">{entity.description}</p>
          </div>
          <div className="register__form-wrapper">
            <input
              placeholder="Email"
              type="email"
              className={emailClassName}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="register__form-wrapper">
            <input
              placeholder="Password"
              type="password"
              className={passwordClassName}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
          />
          </div>
          <div className="register__form-wrapper">
            <input
              placeholder="Confirm Password"
              type="password"
              className={verifyClassName}
              value={verify}
              onChange={(e) => setVerify(e.target.value)}
            />
          </div>
          <div className="register__action">
            <button className="register__button" onClick={doRegister}>{registerText}</button>
            <Link className="register__forgot" to="/login">Back to Login</Link>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Register;
