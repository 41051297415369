import React from 'react';
import fetch from 'node-fetch';
import { withRouter } from 'react-router';
import ScaleLoader from 'react-spinners/ScaleLoader';
import config from '../../config';
import './index.scss';

export const Landing = withRouter(({ history }) => {
  const styles = {
    marginTop: (window.innerHeight - 200) / 2
  };
  fetch(`${config.host}/advertiser/protected`, {credentials: 'include'})
    .then(res => res.json())
    .then(json => {
      if ('status' in json & json.msg === 'not_authorized') {
        history.push('/login');
      } else {
        history.push('/campaign');
      }
    });

  return (
    <div className="landing" style={styles}>
      <ScaleLoader
        sizeUnit={"px"}
        height={20}
        color="#2EA2BB"
      />
    </div>
  )
});

export default Landing;
