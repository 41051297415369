import React, { useState } from 'react';
import { Link } from "react-router-dom";
import ScaleLoader from 'react-spinners/ScaleLoader';
import { getRootStyles } from './helpers/getRootStyles'
import { fetchSubmit } from './helpers/fetchSubmit';
import isValidEmail from './helpers/isValidEmail';
import logo from './assets/logo.png';
import './index.scss';

export const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();

  let errorMessageBox;
  let successMessageBox;

  const styles = getRootStyles();

  let submitText = 'submit';
  if (isLoading) {
    submitText = (
      <ScaleLoader
        sizeUnit={"px"}
        height={10}
        width={4}
        color="#005567"
      />
    );
  }

  const doSubmit = () => {
    if (!isLoading) {
      if (email.length < 1) {
        setErrorMessage('Email is empty.');
        return true;
      }
      if (!isValidEmail(email)) {
        setErrorMessage('Invalid email address.');
        return true;
      }
      setIsLoading(true);
      fetchSubmit(email).then((returnValue) => {
        setIsLoading(false);
        setErrorMessage(null);
        setSuccessMessage('Reset success. Please check your email to get the new password.');
      }).catch(function(error) {
        setIsLoading(false);
        setErrorMessage('Something wrong happened, please try again later.');
      });
    }
  };

  let emailClassName = 'forgot-password__text';
  if (errorMessage) {
    emailClassName = 'forgot-password__text forgot-password__text_error';
    errorMessageBox = (
      <div className="forgot-password__error-message">{errorMessage}</div>
    );
  }
  if (successMessage) {
    successMessageBox = (
      <div className="forgot-password__success-message">{successMessage}</div>
    );
  }

  return (
    <div className="forgot-password" style={styles}>
      <div className="forgot-password__container">
        <div className="forgot-password__header">
          <img src={logo} alt="Adroady" className="forgot-password__logo" height="30" width="110" />
          <h3 className="forgot-password__title">Forgot Password</h3>
        </div>
        <div className="forgot-password__content">
          <p className="forgot-password__intro">Enter your email address and we will send you a link to reset your password.</p>
          {errorMessageBox}
          {successMessageBox}
          <div className="forgot-password__form-wrapper">
            <input
              placeholder="Email"
              type="email"
              className={emailClassName}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="forgot-password__action">
            <button className="forgot-password__button" onClick={doSubmit}>{submitText}</button>
            <Link className="forgot-password__login" to="/login">Back to Login</Link>
          </div>
        </div>
      </div>
    </div>
  )
};

export default ForgotPassword;
