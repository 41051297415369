import { useState } from 'react';
import fetch from 'node-fetch';
import config from '../../../../config';

let isFetching = false;
let storedData = null;

export const invalidateCampaignList = () => {
  isFetching = false;
  storedData = null;
};

export const getCampaignList = (history) => {
  const [data, setData] = useState(storedData);
  if (!isFetching) {
    isFetching = true;
    fetch(`${config.host}/campaign/list`, {credentials: 'include'})
      .then(res => res.json())
      .then(json => {
        if (json.status === 403) {
          history.push('/login');
        }
        if ('data' in json) {
          storedData = json.data;
          setData(json.data);
        } else {
          setData([]);
        }
      });
  }
  return data;
};

export default getCampaignList;

