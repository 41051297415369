import React, { useState } from 'react';

export const CampaignHostCPM = ({ onChange, value }) => {
  let defaultCar = '';
  let defaultMotorbike = '';

  if (value) {
    value.map(item => {
      if (item.host_type_id === 1) {
        defaultCar = item.total_impressions;
      } else if (item.host_type_id === 2) {
        defaultMotorbike = item.total_impressions;
      }
      return true;
    });
  }
  const [hostCar, setHostCar] = useState(defaultCar);
  const [hostMotorbike, setHostMotorbike] = useState(defaultMotorbike);

  const sendChanges = ({ car, motorbike}) => {
    const data = [];
    if (1 * car > 0) {
      data.push({ host_type_id: 1, total_impressions: 1 * car});
    }
    if (1 * motorbike > 0) {
      data.push({ host_type_id: 2, total_impressions: 1 * motorbike});
    }

    onChange(data);
  }

  return (
    <div>
      <h3 className="campaign-form__section-header">Campaign Host's Impressions</h3>
      <div className="campaign-form__hosts">
        <div className="campaign-form__item">
          <label className="campaign-form__label" htmlFor="campaign_car">Car</label>
          <div className="campaign-form__input">
            <input
              className="campaign-form__car"
              type="text"
              id="campaign_car"
              value={hostCar}
              onChange={e => {
                setHostCar(e.target.value);
                sendChanges({
                  car: e.target.value,
                  motorbike: hostMotorbike,
                });
              }}
            />
            <span className="campaign-form__info"># units.</span>
          </div>
        </div>
        <div className="campaign-form__item">
          <label className="campaign-form__label" htmlFor="campaign_motorbike">Motorbike</label>
          <div className="campaign-form__input">
            <input
              className="campaign-form__motorbike"
              type="text"
              id="campaign_motorbike"
              value={hostMotorbike}
              onChange={e => {
                setHostMotorbike(e.target.value);
                sendChanges({
                  car: hostCar,
                  motorbike: e.target.value,
                });
              }}
            />
            <span className="campaign-form__info"># units.</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignHostCPM;
