import { useState } from 'react';
import fetch from 'node-fetch';
import config from '../../../../config';

let isFetching = false;
let storedData = [];
export const fetchEntityTypes = () => {
  const [data, setData] = useState(storedData);
  if (!isFetching) {
    isFetching = true;
    fetch(`${config.host}/advertiser/entity-types`)
      .then(res => res.json())
      .then(json => {
        if ('msg' in json) {
          storedData = json.msg.map((item) => ({
            value: item.id,
            label: item.entity,
            description: item.description,
          }));
          setData(storedData);
        }
      });
  }
  return data;
};

export default fetchEntityTypes;
