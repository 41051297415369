import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { Link } from "react-router-dom";
import ScaleLoader from 'react-spinners/ScaleLoader';
import postLogin from './helpers/postLogin';
import getRootStyles from './helpers/getRootStyles';
import isValidEmail from './helpers/isValidEmail';
import logo from './assets/logo.png';
import './index.scss';

export const Login = withRouter(({ history }) => {
  const styles = getRootStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validationReport, setValidationReport] = useState({
    isEmailValid: true,
    isPasswordValid: true,
    message: '',
    isValid: true
  });

  const doLogin = () => {
    if (!isLoading) {
      if (email.length < 1 || password.length < 1) {
        setValidationReport({
          isEmailValid: false,
          isPasswordValid: false,
          message: 'All fields is required.',
          isValid: false,
        });
        return true;
      }
      if (!isValidEmail(email)) {
        setValidationReport({
          isEmailValid: false,
          isPasswordValid: true,
          message: 'Invalid email address.',
          isValid: false,
        });
        return true;
      }
      setIsLoading(true);
      postLogin({email, password}).then((returnValue) => {
        if (returnValue.success) {
          setIsLoading(false);
          setValidationReport({
            isEmailValid: true,
            isPasswordValid: true,
            message: '',
            isValid: true
          });
          history.push('/');
        } else {
          setIsLoading(false);
          setValidationReport({
            isEmailValid: true,
            isPasswordValid: true,
            message: 'Login failed. Please try again later.',
            isValid: false
          });
        }
      }).catch(function(error) {
        setIsLoading(false);
        setValidationReport({
          isEmailValid: true,
          isPasswordValid: true,
          message: 'Login failed. Please try again later.',
          isValid: false
        });
      });
    }
  };

  let loginText = 'Login';
  if (isLoading) {
    loginText = (
      <ScaleLoader
        sizeUnit={"px"}
        height={10}
        width={4}
        color="#005567"
      />
    );
  }

  const emailClassName = (validationReport.isEmailValid) ? 'login__text' : 'login__text login__text_error';
  const passwordClassName = (validationReport.isPasswordValid) ? 'login__text' : 'login__text login__text_error';

  let successMessageBox = null;
  let errorMessageBox = null;

  if (validationReport.message && !validationReport.isValid) {
    errorMessageBox = (
      <div className="login__error-message">{validationReport.message}</div>
    );
  }

  return (
    <div className="login" style={styles}>
      <div className="login__container">
        <div className="login__header">
          <img src={logo} alt="Adroady" className="login__logo" height="30" width="110" />
          <h3 className="login__title">Login</h3>
        </div>
        <div className="login__content">
          {errorMessageBox}
          {successMessageBox}
          <div className="login__form-wrapper">
            <input
              placeholder="Email"
              type="email"
              className={emailClassName}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') doLogin();
              }}
            />
          </div>
          <div className="login__form-wrapper">
            <input
              placeholder="Password"
              type="password"
              className={passwordClassName}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') doLogin();
              }}
            />
          </div>
          <div className="login__action">
            <button className="login__button" onClick={doLogin}>{loginText}</button>
            <Link className="login__forgot" to="/forgot-password">Forgot Password</Link>
          </div>
        </div>
      </div>
      <Link className="login__register" to="/register">Register</Link>
    </div>
  )
});

export default Login;

