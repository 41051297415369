import React from 'react';
import Header from '../../libraries/header';

export const Dashboard = () => (
  <div className="dashboard">
    <Header />
    <div className="container">
      Dashboard
    </div>
  </div>
);
