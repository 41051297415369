import React from 'react';
import { Route } from "react-router-dom";
import { Login } from './components/login';
import { Register } from './components/register';
import { ForgotPassword } from './components/forgot-password';

export const NonSecure = () => (
  <div>
     <Route path="/login" exact component={Login} />
     <Route path="/forgot-password" exact component={ForgotPassword} />
     <Route path="/register" exact component={Register} />
  </div>
);

export default NonSecure;

