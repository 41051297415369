import React from 'react';
import { withRouter } from "react-router";
import Header from '../../../../libraries/header';
import Footer from '../../../../libraries/footer';
import CampaignForm from '../../libraries/campaign-form';
import postCampaign from './helpers/postCampaign';
import './index.scss';

export const CampaignNew = withRouter(({ history }) => {
  const submitForm = async (data) => {
    let url = null;
    switch (data.campaignType) {
      case 1:
        url = '/campaign/exclusive/create';
        break;
      case 2:
        url = '/campaign/cpm/create';
        break;
      case 3:
        url = '/campaign/cpmv/create';
        break;
      default:
        break;
    }
    return postCampaign({url, params: data});
  };

  return (
    <div className="campaign-new">
      <Header />
      <div className="container">
        <div className="campaign-new__content">
          <div className="campaign-new__header">
            <h2 className="campaign-new__title">New Campaign</h2>
          </div>
          <CampaignForm
            history={history}
            submitForm={submitForm}
          />
        </div>
      </div>
    <Footer />
    </div>
  );
});

export default CampaignNew;
