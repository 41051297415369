import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Secure } from './secure';
import { NonSecure } from './non-secure';
import { Landing } from './components/landing';

export const App = () => (
  <Router>
    <div>
      <Route exact path="/" render={Landing}/>
      <Secure />
      <NonSecure />
    </div>
  </Router>
);

export default App;
