import React, { useState } from 'react';
import Select from 'react-select';

export const CampaignHostExclusive = ({ onChange, value }) => {
  const percentageOptions = [
    {value: 20, label: '20'},
    {value: 40, label: '40'},
    {value: 60, label: '60'},
    {value: 80, label: '80'},
    {value: 100, label: '100'},
  ];

  let defaultCar = '';
  let defaultMotorbike = '';
  let defaultCarPercentage = percentageOptions[0];
  let defaultMotorbikePercentage = percentageOptions[0];

  if (value) {
    value.map(item => {
      if (item.host_type_id === 1) {
        defaultCar = item.total_hosts;
        percentageOptions.map(itemPercent => {
          if (item.percent_sov === itemPercent.value) {
            defaultCarPercentage = itemPercent;
          }
          return true;
        });
      } else if (item.host_type_id === 2) {
        defaultMotorbike = item.total_hosts;
        percentageOptions.map(itemPercent => {
          if (item.percent_sov === itemPercent.value) {
            defaultMotorbikePercentage = itemPercent;
          }
          return true;
        });
      }
      return true;
    });
  }

  const [hostCar, setHostCar] = useState(defaultCar);
  const [hostMotorbike, setHostMotorbike] = useState(defaultMotorbike);
  const [carPercentage, setCarPercentage] = useState(defaultCarPercentage);
  const [motorbikePercentage, setMotorbikePercentage] = useState(defaultMotorbikePercentage);

  const selectProps = {
    placeholder: "",
    options: percentageOptions,
    styles: {
      container: styles => ({
        ...styles,
        width: 90,
        display: 'inline-block',
      })
    }
  };

  const sendChanges = ({
    car,
    motorbike,
    carPercentage,
    motorbikePercentage
  }) => {
    const data = [];
    if (1 * car > 0 && 1 * carPercentage > 0) {
      data.push({
        host_type_id: 1,
        total_hosts: 1 * car,
        percent_sov: 1 * carPercentage
      });
    }
    if (1 * motorbike > 0 && 1 * motorbikePercentage > 0) {
      data.push({
        host_type_id: 2,
        total_hosts: 1 * motorbike,
        percent_sov: 1 * motorbikePercentage
      });
    }

    onChange(data);
  }
  return (
    <div>
      <h3 className="campaign-form__section-header">Campaign Host's Total Units and Share Percentage</h3>
      <div className="campaign-form__hosts">
        <div className="campaign-form__item">
          <label className="campaign-form__label" htmlFor="campaign_car">Car</label>
          <div className="campaign-form__input">
            <input
              className="campaign-form__car"
              type="text"
              id="campaign_car"
              value={hostCar}
              onChange={e => {
                setHostCar(e.target.value);
                sendChanges({
                  car: e.target.value,
                  motorbike: hostMotorbike,
                  carPercentage: carPercentage.value,
                  motorbikePercentage: motorbikePercentage.value,
                });
              }}
            />
            <span className="campaign-form__info"># units, </span>
            <Select
              {...selectProps}
              value={carPercentage}
              onChange={e => {
                console.log('changes', e);
                setCarPercentage(e);
                sendChanges({
                  car: hostCar,
                  motorbike: hostMotorbike,
                  carPercentage: e.value,
                  motorbikePercentage: motorbikePercentage.value,
                });
              }}
            />
            <span className="campaign-form__info">% share of voice.</span>
          </div>
        </div>
        <div className="campaign-form__item">
          <label className="campaign-form__label" htmlFor="campaign_motorbike">Motorbike</label>
          <div className="campaign-form__input">
            <input
              className="campaign-form__motorbike"
              type="text"
              id="campaign_motorbike"
              value={hostMotorbike}
              onChange={e => {
                setHostMotorbike(e.target.value);
                sendChanges({
                  car: hostCar,
                  motorbike: e.target.value,
                  carPercentage: carPercentage.value,
                  motorbikePercentage: motorbikePercentage.value,
                });
              }}
            />
            <span className="campaign-form__info"># units, </span>
            <Select
              {...selectProps}
              value={motorbikePercentage}
              onChange={e => {
                setMotorbikePercentage(e);
                sendChanges({
                  car: hostCar,
                  motorbike: hostMotorbike,
                  carPercentage: carPercentage.value,
                  motorbikePercentage: e.value,
                });
              }}
            />
            <span className="campaign-form__info">% share of voice.</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignHostExclusive;
