import React, { useState } from 'react';
import fetch from 'node-fetch';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import config from '../../../../../../../config';
import { invalidateCampaignList } from '../../../../helpers/getCampaignList';

library.add(faTrash);

export const ActionDelete = ({ id, history }) => {
  const [isLoading, setIsLoading] = useState(false);

  let icon = <FontAwesomeIcon icon="trash" />;
  if (isLoading) {
    icon = (
      <ScaleLoader
        sizeUnit={"px"}
        height={6}
        width={1}
        color="#000"
      />
    );
  }

  return (
    <a
      href="#delete"
      className="campaign__action campaign__action_delete"
      onClick={(e) => {
        e.preventDefault();
        if (isLoading) return false;
        if (window.confirm('Are you sure to delete this campaign?')) {
          setIsLoading(true);
          fetch(`${config.host}/campaign/${id}/delete`, {
            credentials: 'include',
            method: 'delete'
          })
            .then(res => res.json())
            .then(json => {
              if (json.success === true) {
                invalidateCampaignList();
                window.location.reload()
              }
              setIsLoading(false);
            });
        }
      }}
    >
      {icon}
    </a>
  );
};

export default ActionDelete;
